
import { Box, Divider } from '@mui/material';

const Main = ({ children }) => {
    return (
      <Box>
        <main>
          {children}
        </main>
      </Box>
    )
}

export default Main;
