import { Button } from '@mui/material';

const FileInputComponent = ({ setFile }) => {

    const handleFileChange = (e) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
      }
    };

    return (
        <Button
            variant="outlined"
            component="label"
        >
            Загрузить шаблон
            <input
                type="file" accept=".html" onChange={handleFileChange}
                hidden
            />
        </Button>
    );
}

export default FileInputComponent;
