import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import Main from './layouts/Main';
import Container from "./components/Container";
import Preview from "./components/Preview";
import FileInputComponent from './components/FileInput';


function App() {
  const replaceItemsFromState = JSON.parse(localStorage.getItem('replaceItems')) || [];

  const [file, setFile] = useState();
  const [replaceItems, setReplaceItems] = useState(replaceItemsFromState);

  const [searchText, setSearchText] = useState('');
  const [replaceText, setReplaceText] = useState('');

  const onAddItem = () => {
    const newItems = [...replaceItems, {
      searchText: searchText,
      replaceText: replaceText
    }];
    setReplaceItems(newItems);
    localStorage.setItem('replaceItems', JSON.stringify(newItems));
    setSearchText('');
    setReplaceText('');
  }

  const onDelItem = (index) => {
    replaceItems.splice(index, 1);
    const newItems = [...replaceItems];
    setReplaceItems(newItems);
    localStorage.setItem('replaceItems', JSON.stringify(newItems));
    setSearchText('');
    setReplaceText('');
  }

  const replaceItemsMapped = replaceItems.map((item, index) => {
    return (
      <React.Fragment key={index}>
        <Grid item xs={5}>
          <TextField
            label="Исходная строка"
            value={item.searchText}
            variant="filled"
            size="small"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Замена"
            value={item.replaceText}
            variant="filled"
            size="small"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={() => onDelItem(index)}>Удалить</Button>
        </Grid>
      </React.Fragment>
    )
  });

  return (
    <>
      <Main>
        <Container>
          <Box marginBottom={2}>
            <FileInputComponent setFile={setFile} />
          </Box>
          <Box>
            <Typography variant="h6">Глобальная автозамена</Typography>
            <Grid container spacing={1}>
              {replaceItemsMapped}
              <Grid item xs={5}>
                <TextField
                  label="Исходная строка"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  variant="filled"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Замена"
                  value={replaceText}
                  onChange={(event) => setReplaceText(event.target.value)}
                  variant="filled"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={onAddItem}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        {file ? 
          <>
            <Container sx={{ marginY: 0, paddingY: 0 }}>
              <Typography variant="h4">Предварительный просмотр</Typography>
            </Container>
            <Preview file={file} replaceItems={replaceItems} />
          </>
        : ''}
      </Main>
    </>
      
  );
}

export default App;
